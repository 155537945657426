<script setup>
import ApiServices from "@/Services/ApiServices"
import  { ref, onMounted } from "vue"

const props = defineProps({
  nid: {
    required: true,
  }
})

const reports = ref(null)

onMounted(()=> {
  ApiServices.getReports(props.nid)
      .then((response) => {
        reports.value = response.data
      })
      .catch((error) => {
        console.log(error)
      })
})
</script>

<template>

    <div class="report-list" v-if="reports">
      <span class="text-h5">Inspection reports</span>
      <v-btn class="text-button float-right mb-2"
             :to= "{
                name: 'new_report',
                params: { nid: props.nid }}">
        Add report</v-btn>
      <v-expansion-panels class="expansion-panels"
                          variant="accordion">
        <v-expansion-panel
            bg-color="#f57c00"
            v-for="report in reports"
            :key="report.nid"
            :report = "reports">
          <v-expansion-panel-title>
            {{report.title}}
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <div class="text-overline">{{report.uid}}</div>
            <div class="memo" v-html="`${ report.field_memo }`">

            </div>
              <div>
                <v-btn class="text-button float-right mb-2"
                       :to= "{
                name: 'ireport',
                params: { uuid: report.uuid }}">
                  Open
                </v-btn>
              </div>
          </v-expansion-panel-text>
           </v-expansion-panel>
      </v-expansion-panels>
    </div>
</template>

<style scoped>

.report-list {
  background-color: rgb(0,0,0, 0.8);
  color: whitesmoke;
  padding: 10px 10px;

}
.v-expansion-panel-text {
  background-color: whitesmoke;
  color: lightslategray;

}
.icon{
  color: #f04e23;
}
</style>