import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ProjectDetails from "@/components/ProjectDetails.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/settings",
    name: "settings",
    component: () =>
      import("../views/SettingsView.vue"),
  },
  {
    path: "/my_projects",
    name: "my_projects",
    component: () =>
        import("../views/MyProjectsView.vue" ),
  },
  {
    path: '/project/:nid',
    name: 'project',
    props: true,
    component: ProjectDetails,
  },
  {
    path: '/new/report/:nid',
    name: 'new_report',
    props: true,
    component: () =>
        import("../components/ReportForm.vue")
  },
  {
    path: '/ireport/:uuid',
    name: 'ireport',
    props: true,
    component: () =>
    import("../views/IReport.vue")
},
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
