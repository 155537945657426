import axios from 'axios'
import { useUserSettingsStore} from "@/store/userSettings";

const baseURL = process.env.VUE_APP_ROOT_API

const apiAnonClient = axios.create({
    baseURL: baseURL,
    withCredentials: false,
    params: { _format: 'json' },
})

// uses interceptor to inject name and password
const apiClient = axios.create({
    baseURL: baseURL,
    withCredentials: true,
    params: { _format: 'json' },
    auth: {
       username: "",
      // username: store.$state.userName,
       //username: settingsStore.userName,
       password: ""
       //password: settingsStore.password
     },
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
})

const jsonApiClient = axios.create({
    baseURL: baseURL,
    withCredentials: true,
    //params: { _format: 'json' },
    auth: {
        username: "",
        // username: store.$state.userName,
        //username: settingsStore.userName,
        password: ""
        //password: settingsStore.password
    },
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
})

const apiClient2 = axios.create({
    baseURL: baseURL,
    withCredentials: true,
    params: { _format: 'json' },
    auth: {
        username: 'admin',
        password: 'Cmni8hn3e@0511'
    },
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
})

apiClient.interceptors.request.use((config) => {
  const settingStore = useUserSettingsStore()
  config.auth.username = settingStore.userName
  config.auth.password = settingStore.password
        console.log('####XXXX', settingStore.password)
   return config
  },
    (error) => {
        return Promise.reject(error);
    })

jsonApiClient.interceptors.request.use((config) => {
    const settingStore = useUserSettingsStore()
    config.auth.username = settingStore.userName
    config.auth.password = settingStore.password
        console.log('####interceptedXXXX', settingStore.password)
    return config
    },
    (error) => {
        return Promise.reject(error);
    }
    )

export default {
    getCSRFToken() {
        return apiAnonClient.get('/session/token')
    },

    getNews() {
        return apiClient.get('/news/titles')
    },
    // retrieves a Drupal view
    getMyProjects() {
        const store = useUserSettingsStore()
        return apiClient.get('/projects/' + store.$state.uid)
    },
    //retrieves a Drupal view
    // todo: delete?
    getProject(nid) {
        return apiClient2.get('/app_project/' + nid)
    },
    // collect the reports associated with this node
    getReports(nid) {
        return apiClient.get('/app_inspection_reports/' + nid)
    },
    getReport(uuid) {
        return jsonApiClient.get('/jsonapi/node/inspection_report/' + uuid)
    }
}