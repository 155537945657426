<script setup>
import ApiServices from "@/Services/ApiServices"
import InspectionReports from "@/components/InspectionReports.vue";
import  { ref, onMounted } from "vue"

const props = defineProps({
  nid: {
    required: true,
  }
})

const project = ref(null)
const encodedAddress = ref('')
const cleanAddress = ref('')

onMounted(()=> {
  ApiServices.getProject(props.nid)
      .then((response) => {
        console.log('response received', response.data)
        console.log(encodedAddress.value)
        project.value = response.data[0]
        // remove the <br >
        cleanAddress.value = project.value.field_adres.replace('<br />', ', ')
        encodedAddress.value = encodeURIComponent(cleanAddress.value)
        console.log(encodedAddress.value)
      })
      .catch((error) => {
        console.log(error)
      })
})
</script>

<template>
  <v-main>
    <div v-if="project">
      <div class="user-details-wrapper">
        <h3 class="text-center mb-2">{{  project.title }}</h3>
        <v-divider color="dark-gray" thickness="2px"></v-divider>
        <div class="mt-2 user-details">
          <f-a-i class="icon" icon="user" />&nbsp;{{ project.field_naam_informeel }} <br>
          <f-a-i class="icon" icon="phone" />&nbsp;<a :href="`tel:${ project.field_telephone }`"> {{ project.field_telephone }}
          &nbsp;<f-a-i class="icon icon-gray" icon="fa-solid fa-up-right-from-square" /></a><br>
          <f-a-i class="icon" icon="mobile" />&nbsp;<a :href="`tel:${ project.field_mobile }`"> {{ project.field_mobile }}
          &nbsp;<f-a-i class="icon icon-gray" icon="fa-solid fa-up-right-from-square" /></a><br>
          <f-a-i class="icon" icon="location" /><a target="_blank" :href="`https://maps.google.com/maps/dir/?api=1&travelmodemode=car&destination=${ encodedAddress }`">{{ cleanAddress }}
          &nbsp;<f-a-i class="icon icon-gray" icon="fa-solid fa-up-right-from-square" /></a><br>
        </div>
      </div>
      <InspectionReports :nid="project.nid"/>
    </div>
  </v-main>
</template>

<style scoped>

.white-line {
  color: white;
}
  .icon {
    color: black;
    margin-right: 10px;
  }
  .icon-gray {
    color: darkgray;
    transform: scale(0.7)
  }
  .user-details-wrapper {
    background-color: #ddd;
    padding: 10px 10px;
  }
  .user-details {
    a {
      color: black;
      text-decoration: none;
      //font-weight: lighter;
    }
    a:hover {
      transform: scale(1.2);
      border: 1px solid whitesmoke;
    }
    a:visited {
      color: black;
    }
    font-size: larger;
  }
  .orange {
    color: #f57c00;
  }
</style>