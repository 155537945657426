import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { library } from "@fortawesome/fontawesome-svg-core";
import {faPhone, faUser, faLocation, faMobile, faUpRightFromSquare, faArrowLeft}
      from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { createPinia } from "pinia";
import { loadFonts } from "./plugins/webfontloader";
import  piniaPluginPersistedState from "pinia-plugin-persistedstate";
import AddToHomescreen from '@owliehq/vue-addtohomescreen';
import './registerServiceWorker'


const pinia = createPinia();
pinia.use(piniaPluginPersistedState)


loadFonts();

library.add(faPhone,faUser, faLocation, faMobile, faUpRightFromSquare, faArrowLeft);

createApp(App)
    .component("f-a-i", FontAwesomeIcon)
    .use(router)
    .use(pinia)
    .use(vuetify)
    .use(AddToHomescreen, {
      buttonColor: '#f57c00',
      })
    .use(createApp())

    .mount("#app");
