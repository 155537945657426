import { defineStore } from "pinia";

export const useUserSettingsStore = defineStore('userSettings', {
    state: () => ({
        userName: 'NXXX',
        uid: '123',
        password:  '1111111'
    }),
    getters: {},
    actions: {
        updateUserName(value) {
            //  apiCall(() => {

            //   })
            //  .then (() => {
            this.userName = value
            //   })
            //   .catch (error => {
            //      console.log(error)
            //   })
        },
        updateUid(value) {
            //  apiCall(() => {

            //   })
            //  .then (() => {
            this.uid = value
            //   })
            //   .catch (error => {
            //      console.log(error)
            //   })
        },
        updateUserPassword(value) {
            //  apiCall(() => {

            //   })
            //  .then (() => {
            this.password = value
            //   })
            //   .catch (error => {
            //      console.log(error)
            //   })
        },
    },
    persist: true
})